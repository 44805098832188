
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useNavigate, useParams } from 'react-router-dom';
import logo from  "../assets/images/vts-logo.png"

export default function ExamInstructions() {
    const [enableFullscreen,setEnabledFullScreen] = useState(false)
    useEffect(()=>{
        if(localStorage.getItem("enableFullscreen") === "true")
            setEnabledFullScreen(true)
    })

    const navigate = useNavigate();
 
    const [targetTime, setTargetTime] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const {assessmentId,collegeId} = useParams()
    useEffect(() => {
      const startCountdown = () => {
        const now = new Date();
        const target = new Date();
        target.setFullYear(2024);
        target.setMonth(6); 
        target.setDate(7); 
        target.setHours(9);
        target.setMinutes(30);
        target.setSeconds(0);
        target.setMilliseconds(0);
  
        if (target < now) {
          target.setDate(target.getDate() + 1);
        }

        setTargetTime(target);
      };
  
      startCountdown();
    }, []);
  
    useEffect(() => {
      let timer;
      if (targetTime) {
        timer = setInterval(() => {
          const now = new Date();
          const difference = targetTime - now;
  
          if (difference <= 0) {
            clearInterval(timer);
            setTimeLeft('Start Exam');
          } else {
            const hours = Math.floor(difference / (1000 * 60 * 60));
            const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((difference % (1000 * 60)) / 1000);
            setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
          }
        }, 1000);
      }
      return () => clearInterval(timer);
    }, [targetTime]);
    function newPopup(url) {
        var popupWindow = window.open(url, 'popUpWindow1', 'resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no,status=no');
    
        if (popupWindow) {
          popupWindow.onload = function () {
            popupWindow.document.documentElement.requestFullscreen();
          };
        }
      }
//     const handleClick = () => {
//         // navigate(`/vts/${assessmentId}/assessment`);
//         openFullscreenPopup(`${process.env.REACT_APP_FRONTEND_URL}/vts-drive/${assessmentId}/assessment`);

//   };

//   const openFullscreenPopup = (url) => {
//     // Open a new window with specified URL
//     const popupWindow = window.open(url, 'popUpWindow1', 'width=' + window.screen.width + ',height=' + window.screen.height);

//     if (popupWindow) {
//       popupWindow.onload = function () {
//         const fullscreenBtn = popupWindow.document.createElement('button');
//         fullscreenBtn.textContent = 'Enter Fullscreen';
//         fullscreenBtn.style.position = 'absolute';
//         fullscreenBtn.style.top = '50%';
//         fullscreenBtn.style.left = '50%';
//         fullscreenBtn.style.transform = 'translate(-50%, -50%)';
//         fullscreenBtn.style.padding = '10px 20px';
//         fullscreenBtn.style.fontSize = '16px';
//         fullscreenBtn.style.cursor = 'pointer';
//         fullscreenBtn.addEventListener('click', function () {
//           popupWindow.document.documentElement.requestFullscreen();
//           fullscreenBtn.style.display = 'none';
//         });

//         popupWindow.document.body.appendChild(fullscreenBtn);
//       };
//     } else {
//       alert('Popup blocked. Please allow popups for this site.');
//     }
//   };

  // Handle the click event to open the popup
  const handleClick = ()=>{
    navigate(`vts-drive/${assessmentId}/assessment`)
  }
 
    return (
        <div>
          <div className="instr-watermark">
            <img src={logo} alt="vts - watermark" />
          </div>
            <Navbar/>
            <div className="instruction">
                <u><h2>Instructions for Online Test</h2></u>
                <h4>Please read the Instructions carefully before starting the test.</h4>
                <ul>
                    <li>Arrange for stable Internet connectivity.</li>
                    <li>Giving examination on Laptop or Desktop is highly recommended.</li>
                    <li>Make sure laptop is fully charged.
                        UPS/Inverter for laptop/desktop should be arranged for uninterrupted power supply.</li>
                    <li> Close all browsers/tabs before starting the online examination.</li>
                    <li>Once the exam starts, do not switch to any other window/tab. On doing so,
                        your attempt may be considered as malpractice and your exam may get
                        terminated.</li>
                    <li>It is recommended to use web browser such as Mozilla and Chrome
                        browsers etc. on a desktop/laptop.</li>
                    <li>Do not use the back button of keyboard or close button/icon to go back to
                        previous page or to close the screen.</li>
                    <li>The clock has been set at server and count down timer at the top right corner of the screen will display left out time to closure from where you can monitor time you have to complete the exam.</li>
                    <h2><li>Students will not be allowed to login after 10 min from the start
                        of examination.</li></h2>
                </ul>
                <div className="writeexam">
                    <u><h2>Answering the question</h2></u>
                    <p>The answers will be saved automatically or you can save it by clicking save&next.</p>
                    <p>There will be three buttons on each screen, the use of buttons is given below:</p>
                    <ul>
                        <li><strong>RESET</strong>: Can be used to deselect a selected answer.</li>
                        <li><strong>SAVE & NEXT</strong>: Save the selected answer and proceed to next question.</li>
                        <li><strong>PREVIOUS</strong>: It will redirect to the previous question.</li>
                        <li>To go to a question, click on the question number on the right side of the screen.</li>
                    </ul>
                    <div className="answer-status">
                    <p><span className="circle green"></span>Answer Attempted</p>
                    <p><span className="circle blue"></span>Answer Not Attempted</p>
                    </div>
                </div>
                <div className="navigate">
                    <b><h3>There will be three categories:</h3></b>
                    <table className="sections-table">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Total No.Of Questions</th>
                                <th>Marks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Arithmetic</td>
                                <td>30</td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>Logical</td>
                                <td>15</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>Verbal</td>
                                <td>15</td>
                                <td>15</td>
                                </tr>
                                <tr className="total-marks-row">
                                <td><strong>Total</strong></td>
                                <td><strong>60</strong></td>
                                <td><strong>60</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li> Candidate will be allowed to Shuffle between questions anytime during the examination as per their convenience.</li>
                        <li>The three categories will be jumbled throughout the whole section.</li>
                        <h2><li>For each correct response, one mark will be awarded. Unanswered questions will be marked as Zero,No Negative Marks.</li></h2>
                        <h2><li>Do Not CLICK on the SUBMIT Button unless you have completed the exam.In case you click SUBMIT button, you will not be permitted to continue.</li></h2>
                    </ul>
                    <button  onClick={handleClick} className={timeLeft === 'Start Exam' ? "examStartBtn" : "examStartBtn inactive"} disabled ={timeLeft === 'Start Exam' ? false : true}>{timeLeft}</button>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
