import React from 'react'

const Contact = () => {
	return (
		<div className="contact pad category d-flex">
			<h2>Contact Us</h2>
			<div className="contactCont d-flex">
				<div className="contactDetails">
					<div className="d-flex">
						<img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="logo" className='logo' height={'60px'} />
						<label>VTS Assessment Portal</label>
					</div>
					<div className="contactCard">
						<img src={process.env.PUBLIC_URL + '/images/location.png'} alt="icon"/>
						<div className="contactDetailCard">
							<h3>Location Details:</h3>
							<p>First Floor, SRP Stratford, Rajiv Gandhi Salai, PTK nagar, OMR Road, Thiruvanmiyur, Chennai, Tamil Nadu 600041</p>
						</div>
					</div>
					<div className="contactCard">
						<img src={process.env.PUBLIC_URL + '/images/email.png'} alt="icon"/>
						<div className="contactDetailCard">
							<h3>Email:</h3>
							<p>info@vtsassessmentportal.com</p>
						</div>
					</div>
					{/* <div className="contactCard">
						<img src={process.env.PUBLIC_URL + '/images/call.png'} alt="icon"/>
						<div className="contactDetailCard">
							<h3>Call:</h3>
							<p>7989193795,8099386299</p>
						</div>
					</div> */}
					<div className="contactCard">
						<img src={process.env.PUBLIC_URL + '/images/clock.png'} alt="icon"/>
						<div className="contactDetailCard">
							<h3>Open Hours:</h3>
							<p>Mon-Sat: 9:30AM - 6:30PM</p>
						</div>
					</div>
				</div>
				<form className="contactinpt">
					<div className="name_email">
						<input type="text" name="ContactName" id="" placeholder='Your Name'/>
						<input type="email" name="contactEmail" id="" placeholder='Your Email'/>
					</div>
					<div className="sub_msg">
						<input type="text" className='contactSubject' name="contactSubject" id="" placeholder='Subject'/>
						<textarea className='contactMsg' name="contactMsg" id="" placeholder='Message' rows={10}></textarea>
					</div>
					<button className='contactBtn'>Send Message</button>
				</form>
			</div>
		</div>
	)
}

export default Contact