import React from 'react'

const Services = () => {
	return (
		<div className="pad" id="services">
			<div className="category d-flex">
				<h2>Our Services</h2>
				<div className="serviceCont">
					<div className="serCard d-flex">
						<div className="service d-flex">
							<p className="serName">Talent Assessment</p>
							<p className="serNum">01.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/pen.png'} alt="" className="serIcon"/>
								<p className="serTitle">Talent Assessment</p>
								<p className="serDesp">A suite of scientific assessment tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
					<div className="serCard d-flex">
						<div className="service d-flex">
							<p className="serName">Coding Assessment</p>
							<p className="serNum">02.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/code.png'} alt="" className="serIcon"/>
								<p className="serTitle">Coding Assessment</p>
								<p className="serDesp">Build high-performing coding teams using our suite of coding tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
					<div className="serCard d-flex">
						<div className="service d-flex">
						<p className="serName">L&D Solutions</p>
						<p className="serNum">03.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/l&d.png'} alt="" className="serIcon"/>
								<p className="serTitle">L&D Solutions</p>
								<p className="serDesp">Automate your Learning & Development processes using our digital tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
					<div className="serCard d-flex">
						<div className="service d-flex">
							<p className="serName">Hiring Solutions</p>
							<p className="serNum">04.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/doc.png'} alt="" className="serIcon"/>
								<p className="serTitle">Hiring Solutions</p>
								<p className="serDesp">Assess talent holistically and automate your hiring using our digital tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
					<div className="serCard d-flex">
						<div className="service d-flex">
							<p className="serName">Online Examination</p>
							<p className="serNum">05.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/pen.png'} alt="" className="serIcon"/>
								<p className="serTitle">Online Examination</p>
								<p className="serDesp">Conduct online exams at scale using our suite of digital tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
					<div className="serCard d-flex">
						<div className="service d-flex">
							<p className="serName">Remote Proctoring</p>
							<p className="serNum">06.</p>
						</div>
						<div className="scrollCardHover d-flex">
							<div className="serSccrollCard">
								<img src={process.env.PUBLIC_URL + '/images/cam.png'} alt="" className="serIcon"/>
								<p className="serTitle">Remote Proctoring</p>
								<p className="serDesp">Ensure cheating-free online exams using our suite of proctoring tools</p>
								<button className="serBtn">EXPLORE</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Services