import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import About from '../components/About';
import Services from '../components/Services';
import Choose from '../components/Choose';
import Footer from '../components/Footer';
import Contact from '../components/Contact';

const Home = () => {
	return (
		<>
			<Navbar/>
			<Hero/>
			<About/>
			<Services/>
			<Choose/>
			<Contact/>
			<Footer/>
		</>
	)
}

export default Home