import React from 'react'

const Choose = () => {
	return (
		<div className="chooseCont">
			<p>Why Choose VTS's Talent Assessments</p>
			<div className="reasonCont">
				<div className="reasons">
					<img src="https://assetsprelogin.mettl.com/_next/image/?url=%2Fassets%2Fhomepage%2FScalable-Solutions.svg&w=128&q=75" alt="" />
					<p className="chooseTitle">Scalable Solutions</p>
					<p className="chooseDesp">We conduct 200,000+ proctored assessments in a day.</p>
				</div>
				<div className="reasons">
					<img src="https://assetsprelogin.mettl.com/_next/image/?url=%2Fassets%2Fhomepage%2F247-Support.svg&w=128&q=75" alt="" />
					<p className="chooseTitle">24*7 Support</p>
					<p className="chooseDesp">We have your back, always.</p>
				</div>
				<div className="reasons">
					<img src="https://assetsprelogin.mettl.com/_next/image/?url=%2Fassets%2Fhomepage%2FProctoring.svg&w=128&q=75" alt="" />
					<p className="chooseTitle">Best-in-class Proctoring</p>
					<p className="chooseDesp">Ensure cheating-free online exams using remote proctoring technology.</p>
				</div>
				<div className="reasons">
					<img src="https://assetsprelogin.mettl.com/_next/image/?url=%2Fassets%2Fhomepage%2FCustomizable-solutions.svg&w=128&q=75" alt="" />
					<p className="chooseTitle">Customizable Solutions</p>
					<p className="chooseDesp">We design our solutions to suit your unique requirements.</p>
				</div>
			</div>
		</div>
	)
}

export default Choose