import "../../Styles/Portalpage.css";
import React from 'react'

const Instructions = ({closeInfo}) => {
    const handleCloseInfo=()=>{
        closeInfo()
    }
  return (
    <div>
      <div className="info-card">
      
            <div className="container">
            <button onClick={handleCloseInfo} className="close-btn">
              X
            </button>
              <div className="instruction">
                <div className="writeexam">
                  <u>
                    <h2>Answering the question</h2>
                  </u>
                  <p>
                    The answers will be saved automatically or you can save it
                    by clicking save&next.
                  </p>
                  <p>
                    There will be three buttons on each screen, the use of
                    buttons is given below:
                  </p>
                  <ul>
                    <li>
                      <strong>RESET</strong>: Can be used to deselect a selected
                      answer.
                    </li>
                    <li>
                      <strong>SAVE & NEXT</strong>: Save the selected answer and
                      proceed to next question.
                    </li>
                    <li>
                      <strong>PREVIOUS</strong>: It will redirect to the
                      previous question.
                    </li>
                    <li>
                      To go to a question, click on the question number on the
                      right side of the screen.
                    </li>
                  </ul>
                  <div className="answer-status">
                    <p>
                      <button className="green"></button>Attempted
                    </p>
                    <p>
                      <button className="white"></button>Unattempted
                    </p>
                    <p>
                      <button className="blue"></button> Current
                    </p>
                  </div>
                </div>

                <div className="navigate">
                  <u>
                    <h2>Navigating through the Sections</h2>
                  </u>
                  <b>
                    <h2>There will be total of three Categories:</h2>
                  </b>
                  <table className="sections-table">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Total No.Of Questions</th>
                        <th>Marks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Arithmetic</td>
                        <td>30</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>Logical</td>
                        <td>15</td>
                        <td>15</td>
                      </tr>
                      <tr>
                        <td>Verbal</td>
                        <td>15</td>
                        <td>15</td>
                      </tr>
                      <tr className="total-marks-row">
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>60</strong>
                        </td>
                        <td>
                          <strong>60</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ul>
                    <li>
                      The three categories will be jumbled throughout the whole
                      section.
                    </li>
                    <li>
                      You can shuffle between questions anytime during the time
                      stipulated for the exam.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Instructions
