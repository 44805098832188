import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import banner from "../assets/images/banner.png"
const ReportComponent = ({userdata}) => {
    const [candidate, setCandidate] = useState(userdata);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // State to track if the image is loaded
    const [imageLoaded, setImageLoaded] = useState(false);
    const [date,setdate] = useState(new Date())
    useEffect(() => {
        // Function to preload the image
        const preloadImage = () => {
            const img = new Image();
            img.onload = () => {
                setImageLoaded(true);
            };
            img.src = 'https://res.cloudinary.com/dlo7urgnj/image/upload/v1720529524/logo_uvnz1f.png';
        };

        preloadImage();
    }, []);

    const handleDownloadPDF = async () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4'
        });

        const captureReport = async () => {
            const reportElement = document.getElementById('reportContent');

            try {
                if (!imageLoaded) {
                    throw new Error('Image not loaded yet');
                }

                const canvas = await html2canvas(reportElement, { scale: 1 });
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = doc.internal.pageSize.width;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

                doc.save(`${userdata.name} VTS Assessment Report.pdf`);
            } catch (error) {
                console.error('Error generating PDF:', error);
                alert('Error generating PDF. Please try again later.');
            }
        };

        captureReport();
    };

    return (
        <div className="container-report">
            <div  id="reportContent">
                <div className='res-nav'>
                    <div style={{display:"flex",alignItems:"center"}} className='res-logo'>
                        <img src={banner} alt="" />
                        <h1>VTS</h1>
                    </div>
                    <div>
                        <h1>Assessment Report</h1>
                    </div>
                </div>

                <div className='res-card-main'>
                    <div className='card-results'>
                        <div className="card-results-pes-detls">
                            <h2 style={{ color: 'blue'  }}>Candidate Details</h2>
                            <p><b>Candidate Name:</b> {candidate.name}</p>
                            <p><b>Candidate ID:</b> {candidate.collegeId}</p>
                            <p><b>Email ID:</b> {candidate.email}</p>
                            <p><b>Phone Number:</b> NAN </p>
                            {/* <p><b>Phone Number:</b> {candidate.phoneNumber}</p> */}
                        </div>
                        <div className='card-results-pes-detls'>
                            <h2 style={{ color: 'blue' }}>Exam Details</h2>
                            <p><b>Total Score:</b> 60</p>
                            <p><b>Your Score:</b> {candidate.score}</p>
                            <p><b>College Name:</b> {candidate.collegeName}</p>
                            <p><b>VTS DRIVE Batch 2025</b></p>
                        </div>
                    </div>

                    <table style={{ width: '70vw', borderCollapse: 'collapse', margin: '10px auto', border: '2px solid #000', textAlign: 'center' }}>
                        <thead style={{ backgroundColor: '#261648', color: '#fff' }}>
                            <tr>
                                <th style={{ padding: '10px', border: '1px solid #000' }}>Section</th>
                                <th style={{ padding: '10px', border: '1px solid #000' }}>No. of Questions Attempted</th>
                                <th style={{ padding: '10px', border: '1px solid #000' }}>No Of Correct Answers</th>
                                <th style={{ padding: '10px', border: '1px solid #000' }}>Score</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>Aptitude</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>{candidate.attempted}</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>{candidate.correct}</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>{candidate.score}</td>
                            </tr>
                            {/* <tr>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>Logical</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>15</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>13</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>Verbal</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>15</td>
                                <td style={{ padding: '10px', border: '1px solid #000' }}>12</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>

                <div>
                    <h4 style={{ textAlign: 'center' }}>{date.toLocaleDateString("en-US", options)}</h4>
                    <h3 style={{ textAlign: 'center' }}>Thanks For Choosing VTS Enterprises India Private Limited</h3>
                </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <button className="btn btn-primary view-report-btn" onClick={handleDownloadPDF}>Download as PDF</button>
            </div>
        </div>
    );
};

export default ReportComponent;