// import React, { useState } from 'react';
// import { RegisterFrom } from './RegisterFrom';
// import { redirect, useNavigate } from 'react-router-dom';

// const Hero = () => {
//     const [showReg, setShowReg] = useState(false);
//     const navigate = useNavigate()
//     const [isRegistered,setRegistered] = useState(false)
//     const [assessmentLink,setAssessmentLink] = useState("www.google.com")
//     const handleRegistration = (url)=>{
//         setRegistered(true)
//         setAssessmentLink(url)
//     }

//     const handleReg = () => {
//         setShowReg(bool => !bool);
//     };
//     const handleAssessment = ()=>{
//         window.open(assessmentLink,'_blank')
//     }
//     const text = "VTS EXCELLENCE";
//     const characters = text.split('');

//     return (
//         <div className='hero d-flex pad' id='home'>
//             {showReg && <RegisterFrom onRegister = {handleRegistration} onClose={handleReg} />}
//             <div className="heroContent">
//                 <h1>
//                     {characters.map((char, index) => (
//                         <span key={index} className="char" data-index={index}>{char === ' ' ? '\u00A0' : char}</span>
//                     ))}
//                 </h1>
//                 <p>Conduct Customized Online Assessments on our Powerful Cloud-based Platform, Secured with Best-in-class Proctoring</p>
//                 {!isRegistered && (<button className="getStarted" onClick={handleReg}>Register Here</button>)}
//                 {isRegistered && (<button className="getStarted" onClick={() => { window.open(assessmentLink, '_blank'); }}>Assessment Link</button>)}
//             </div>
//             <img src={process.env.PUBLIC_URL + '/images/onlineTest.gif'} className="heroImg" alt="heroImg" />
//         </div>
//     );
// };

// export default Hero;

import { Button, Form, Input, Select, Row, Modal, Col, Space } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  UserOutlined,
  MailOutlined,
  MobileOutlined,
  BankOutlined,
  ApartmentOutlined,
  ReadOutlined,
  PercentageOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const { Option } = Select;

const Hero = () => {
  const [formInst] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [clientReady, setClientReady] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { assessmentId } = useParams();
  const [isRegistered, setRegistered] = useState(false);
  const [assessmentLink, setAssessmentLink] = useState("www.google.com");
  const handleRegistration = (url) => {
    setRegistered(true);
    setAssessmentLink(url);
  };
  const handleReg = () => {
    setModalOpen(true);
  };
  const onFinish = (values) => {
		console.log('Received values of form: ', values);
		const data = {
			fullname: values.fullname,
			email: values.email,
      gender:values.gender,
			phone: values.phone,
			college_Id: values.college_Id,
			college_name: values.college_name,
			course: values.course,
			dept: values.dept,
			cgpa: values.cgpa
		};
	
		const url = `${process.env.REACT_APP_BASE_URL}/candidates/${assessmentId}/registrations`;
		axios.post(url, data)
			.then(res => {
				console.log("registration successful");
				handleRegistration(res.data);
				Modal.success({
					title: 'Registration Successful',
					content: 'Registration Successful! Please Click the Assessment Link Button to open the Assessment',
					footer: (
						<div className="centered-buttons">
							<Button key="ok" type="primary" onClick={() => { Modal.destroyAll(); }}>
								OK
							</Button>
							<Button key="check-mail" onClick={() => { window.open(res.data, '_blank'); }}>
								Start Assessment
							</Button>
						</div>
					),
				});
			})
			.catch(err => {
				console.log(err);
				let errorMessage = 'Registration Failed. Please try again.';
				if (err.response && err.response.data && err.response.data.error === 'User already exists') {
					errorMessage = 'User already exists.';
				}
				Modal.error({
					title: 'Registration Failed',
					content: errorMessage,
					footer: (
						<div className="centered-buttons">
							<Button key="ok" type="primary" onClick={() => { Modal.destroyAll(); }}>
								OK
							</Button>
						</div>
					),
				});
			});
	};
	
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleModal = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setModalOpen(false);
    }, 3000);
  };

  useEffect(() => {
    setClientReady(true);
  }, []);

  return (
    <div className="hero d-flex pad" id="home">
      <ToastContainer />
      {modalOpen && (
        <div className="regFormCont">
          <Form
            form={formInst}
            name="register"
            className="regForm"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            style={{
              maxWidth: 600,
            }}
            scrollToFirstError
          >
            <span className="cross" onClick={closeModal}>
              &#x2715;
            </span>
            <p className="regTitle">Get Better Talent Assessment Now</p>
            <Form.Item
              name="fullname"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid Name!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Full Name"
              />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid Email ID!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder="Email ID"
              />
            </Form.Item>

            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: /^\d{10}$/,
                  message: "Please enter a valid phone number!",
                },
              ]}
            >
              <Input
                prefix={<MobileOutlined className="site-form-item-icon" />}
                type="number"
                placeholder="Phone Number"
              />
            </Form.Item>

            <Form.Item
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Please select a valid College!",
                },
              ]}
            >
              <Select
                prefix={<BankOutlined />}
                placeholder="Gender"
              >
                <Option value="Female">Female</Option>
                <Option value="Male">Male</Option>
                
              </Select>
            </Form.Item>
            <Form.Item
              name="college_name"
              rules={[
                {
                  required: true,
                  message: "Please select a valid College!",
                },
              ]}
            >
              <Select
                prefix={<BankOutlined />}
                placeholder="select your College"
              >
                <Option value="Shree Venkateshwara Arts and Science College">Shree Venkateshwara Arts and Science College</Option>
                <Option value="Saveetha University">Saveetha University</Option>
                <Option value="KL University">KL University</Option>
                <Option value="DHANALAKSHMI SRINIVASAN ENGINEERING COLLEGE">DHANALAKSHMI SRINIVASAN ENGINEERING COLLEGE</Option>
                <Option value="JNTUK">JNTUK</Option>
                <Option value="SBIT">SBIT</Option>
                <Option value="LIET">LIET</Option>
                <Option value="GIET">GIET</Option>
                <Option value="SWARNA ANDHRA COLLEGE">
                  SWARNA ANDHRA COLLEGE
                </Option>
                <Option value="RAMCO INSTITUTE OF TECHNOLOGY">RAMCO INSTITUTE OF TECHNOLOGY</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="course"
              rules={[
                {
                  required: true,
                  message: "Please select a valid Course!",
                },
              ]}
            >
              <Select
                prefix={<ApartmentOutlined className="site-form-item-icon" />}
                placeholder="select your Course"
              >
                <Option value="B.Tech">B.Tech/BE</Option>
                <Option value="BCom">BCom</Option>
                <Option value="Bsc">Bsc</Option>
                <Option value="MCA">MCA</Option>
                <Option value="MTech">M.Tech</Option>
                <Option value="Other">M.Tech</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="dept"
              rules={[
                {
                  required: true,
                  message: "Please select a valid Department!",
                },
              ]}
            >
              <Select
                prefix={<ReadOutlined className="site-form-item-icon" />}
                placeholder="select your Department"
              >
                <Option value="CSE">CSE</Option>
                <Option value="ECE">ECE</Option>
                <Option value="EEE">ECE</Option>
                <Option value="AI/ML">AI/ML</Option>
                <Option value="OTHER">OTHER</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item
                    name="college_Id"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Invalid College ID!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<BankOutlined className="site-form-item-icon" />}
                      placeholder="Student Reg.ID(ROLL NO)"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="cgpa"
                    noStyle
                    rules={[
                      {
                        required: true,
                        min: 0,
                        max: 2,
                        message: "Invalid CGPA!",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <PercentageOutlined className="site-form-item-icon" />
                      }
                      type="number"
                      placeholder="CGPA"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Space
                  direction="horizontal"
                  style={{ width: "100%", justifyContent: "center" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={handleModal}
                    disabled={
                      !clientReady ||
                      !formInst.isFieldsTouched(true) ||
                      !!formInst
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Register
                  </Button>
                </Space>
              )}
            </Form.Item>
          </Form>
        </div>
      )}
      <div className="heroContent">
        <h1>VTS Excellence</h1>
        <p>
          Conduct Customized Online Assessments on our Powerful Cloud-based
          Platform, Secured with Best-in-class Proctoring
        </p>
        {!isRegistered && (
          <button className="getStarted" onClick={handleReg}>
            Register Here
          </button>
        )}
        {isRegistered && (
          <button
            className="getStarted"
            onClick={() => {
              window.open(assessmentLink, "_blank");
            }}
          >
            Assessment Link
          </button>
        )}
      </div>
      <img
        src={process.env.PUBLIC_URL + "/images/onlineTest.gif"}
        className="heroImg"
        alt="heroImg"
      />
    </div>
  );
};

export default Hero;
