import React from 'react'

const ResultPage = () => {
  return (
    <div>
      Test submitted Successfully
    </div>
  )
}

export default ResultPage
