import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const PreventActionsComponent = ({ children, finishTest }) => {
  var copyCount = 3;
  var tabswitches = 3;

  const [isWarningMessageVisible, setIsWarningMessageVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleRightClick = (e) => {
    e.preventDefault();
  };

  document.addEventListener('contextmenu', handleRightClick);

  const handleCopy = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.ctrlKey && e.key === 'c') {
      if (copyCount === 1 || tabswitches === 1) {
        finishTest(`Submitted due to malpractice remaining copy count: ${copyCount} tabswitches remaining: 0}`);
      }
      e.preventDefault();
      copyCount -= 1;
      setWarningMessage(`Copying is not allowed! \n ${copyCount} remaining`);
      setIsWarningMessageVisible(true);
    }
  };

  const handleVisibilityChange = (e) => {
    e.preventDefault();
    if (document.hidden) {
      if (copyCount === 1 || tabswitches === 1) {
        finishTest(`Submitted due to malpractice remaining copy count: ${copyCount} tabswitches remaining: ${tabswitches}`);
      }
      tabswitches -= 1;
      setWarningMessage(`Switching tabs is not allowed. The test will be automatically submitted if you attempt to switch tabs again.`);
      setIsWarningMessageVisible(true);
    }
  };

  const handleWarningMessageOk = () => {
    setIsWarningMessageVisible(false);
  };

  useEffect(() => {
    document.addEventListener('copy', handleCopy);
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      {children}
      <Modal
        title={
          <span>
            <ExclamationCircleOutlined style={{ color: 'red', marginRight: '10px' }} />
            WARNING !!!!
          </span>
        }
        visible={isWarningMessageVisible}
        onOk={handleWarningMessageOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleWarningMessageOk}>
            OK
          </Button>,
        ]}
      >
        <p>{warningMessage}</p>
      </Modal>
    </>
  );
};

export default PreventActionsComponent;
